@import url(https://fonts.googleapis.com/css?family=Darker+Grotesque:400,900&display=swap&text=POSTERSbyxypnox);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #eaeaf3; }

.App {
  /* The Masonry Container */
  font-family: 'Darker Grotesque';
  margin: 0em auto;
  max-width: 1400px;
  /* The Masonry Brick */
  /* Masonry on large screens */
  /* Masonry on medium-sized screens */
  /* Masonry on small screens */ }
  .App .Gallary {
    padding: 2rem 0 10rem;
    grid-column-gap: 0rem;
    -webkit-column-gap: 0rem;
       -moz-column-gap: 0rem;
            column-gap: 0rem; }
  .App .intro {
    padding: 1rem;
    margin: 10rem 0 0; }
    .App .intro h1 {
      font-size: 5rem;
      margin: 0;
      font-weight: 900;
      color: #ffffff;
      text-shadow: 0px 2px 2px rgba(121, 122, 136, 0.5), 3px 6px 0px rgba(255, 0, 0, 0.7), -6px 3px 0px rgba(0, 255, 0, 0.7), 4px -6px 0px rgba(0, 0, 255, 0.7); }
    .App .intro h2 {
      margin: 0;
      margin-top: -2rem;
      font-size: 3rem;
      font-weight: 400; }
      .App .intro h2 a {
        color: #4c576d;
        text-decoration: none; }
  .App .item {
    padding: 1rem 1rem 0.75rem;
    margin: 0rem; }
    .App .item img {
      background: #fff;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      -webkit-transition: 0.25s all ease;
      transition: 0.25s all ease; }
      @media screen and (min-width: 1024px) {
        .App .item img:hover {
          box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.5);
          -webkit-transform: scale(1.075);
                  transform: scale(1.075);
          cursor: pointer; } }
  @media only screen and (min-width: 1024px) {
    .App .Gallary {
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3; }
    .App .intro h1 {
      font-size: 10rem; } }
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    .App .Gallary {
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3; }
    .App .intro h1 {
      font-size: 10rem; } }
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    .App .Gallary {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2; }
    .App .intro h1 {
      font-size: 10rem; } }

