body {
  background: rgb(234, 234, 243);
}
@import url('https://fonts.googleapis.com/css?family=Darker+Grotesque:400,900&display=swap&text=POSTERSbyxypnox');
.App {
  /* The Masonry Container */
  font-family: 'Darker Grotesque';
  margin: 0em auto;
  max-width: 1400px;
  .Gallary {
    padding: 2rem 0 10rem;
    column-gap: 0rem;
  }

  .intro {
    padding: 1rem;
    margin: 10rem 0 0;
    h1 {
      font-size: 5rem;
      margin: 0;
      font-weight: 900;
      color: #ffffff;

      text-shadow: 0px 2px 2px rgba(121, 122, 136, 0.5),
        3px 6px 0px rgba(255, 0, 0, 0.7), -6px 3px 0px rgba(0, 255, 0, 0.7),
        4px -6px 0px rgba(0, 0, 255, 0.7);
      // , -7px 7px 0px rgb(122, 0, 0),
      // 0px -7px 0px rgb(2, 0, 110), 7px 7px 0px rgb(0, 97, 8);
    }
    h2 {
      margin: 0;
      margin-top: -2rem;
      font-size: 3rem;
      font-weight: 400;
      a {
        color: rgb(76, 87, 109);
        text-decoration: none;
      }
    }
  }

  /* The Masonry Brick */
  .item {
    padding: 1rem 1rem 0.75rem;
    margin: 0rem;

    img {
      background: #fff;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      transition: 0.25s all ease;

      @media screen and (min-width: 1024px) {
        &:hover {
          box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.5);
          transform: scale(1.075);
          cursor: pointer;
        }
      }
    }
  }

  /* Masonry on large screens */
  @media only screen and (min-width: 1024px) {
    .Gallary {
      column-count: 3;
    }
    .intro {
      h1 {
        font-size: 10rem;
      }
    }
  }

  /* Masonry on medium-sized screens */
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    .Gallary {
      column-count: 3;
    }
    .intro {
      h1 {
        font-size: 10rem;
      }
    }
  }

  /* Masonry on small screens */
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    .Gallary {
      column-count: 2;
    }
    .intro {
      h1 {
        font-size: 10rem;
      }
    }
  }
}
